import { Text } from "src/components/01_atoms/text";
import { styled } from "src/styles/stitches/theme";

export const WrapperContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "0 1rem",
  gap: "1.5rem",
  marginTop: "15rem",

  "@bp3": {
    width: "27rem",
    marginRight: "13.25rem",
    justifyContent: "center",
    padding: "0",
    marginTop: "0rem",
    gap: "0rem",
  },

  "&.xmas-active": {
    marginTop: "10rem",

    "@bp3": {
      marginTop: "0rem",
    },
  },
});

export const WrapperTitle = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginTop: "0rem",
  gap: "0.6rem",

  "@bp3": {
    gap: "1rem",
    marginBottom: "3rem",
    marginTop: "3rem",
  },

  ".stroked": {
    textDecoration: "line-through",
    opacity: 0.3,
  },
});

export const WrapperHeaderContent = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "1.2rem",

  "@bp3": {
    marginTop: "2rem",
  },
});

export const CopyRAFCard = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0.6rem 1rem",
  backgroundImage: "url(/assets/media/background-raf-copy-card.png)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0% 0%",
  backgroundSize: "contain",
  margin: "1.6rem 0",

  "@bp3": {
    padding: "1rem",
    margin: "0rem 0rem",
    marginBottom: "3rem",
  },
});

export const PushAppCard = styled("div", {
  display: "flex",
  width: "100%",
  backgroundColor: "#3b4cbd",
  padding: "1rem",
  borderRadius: 20,
  position: "relative",
  overflow: "hidden",
  marginBottom: "0rem",

  "@bp3": {
    marginBottom: "3rem",
  },
});

export const WrapperRAFCardTitle = styled("div", {
  display: "flex",
  flexDirection: "column",

  "@bp3": {},
});

export const WrappperContentPushAppCard = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  marginRight: "2rem",

  "@bp3": {
    marginRight: "0rem",
  },
});

export const WrapperTitlePushAppCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "204px",
  gap: "4px",

  "@bp3": {},
});

export const WrapperQRCode = styled("div", {
  display: "none",
  justifyContent: "center",
  width: "96px",
  height: "96px",
  backgroundColor: "#4957bc",
  borderRadius: 12,
  padding: "12px",
  marginLeft: 20,

  "@bp3": {
    display: "flex",
  },
});

export const QRCode = styled("div", {
  backgroundImage: "url(/assets/media/QR-code.png)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0% 0%",
  backgroundSize: "contain",
  width: "100%",
  height: "100%",

  "@bp3": {},
});

export const PushAppImage = styled("img", {
  position: "absolute",
  left: 10,
  bottom: 0,
  width: "74px",
  height: "76px",

  "@bp3": {
    left: 0,
    bottom: 0,
    width: "88px",
    height: "110px",
  },
});

export const BackgroundImage = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 90%",
  backgroundSize: "cover",

  "@bp3": {
    backgroundPosition: "50% 20%",
  },
});

export const CloseIcon = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  width: "2.75rem",
  height: "2.75rem",
  borderRadius: "0.75rem",
  border: "1px solid #DBEBFF",
  backgroundColor: "#FFFFFF",
});

export const CloseIconMobile = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  cursor: "pointer",
  width: "2.75rem",
  height: "2.75rem",
  borderRadius: "0.75rem",
  border: "1px solid #DBEBFF",
  backgroundColor: "#FFFFFF",
  right: 24,
  top: 24,
});

export const WrapperButton = styled("div", {
  display: "flex",
  position: "absolute",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  top: "3rem",
  right: "3rem",
  width: "2.75rem",
  height: "2.75rem",
  borderRadius: "0.75rem",
  border: "1px solid #DBEBFF",
  backgroundColor: "#FFFFFF",
});

export const Badge = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 4,
  padding: "2px 8px",
  backgroundColor: "rgba(255, 255, 255, 0.16)",
  borderRadius: 8,
});

export const BadgeTime = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 4,
  padding: "2px 8px",
  borderRadius: 8,
});

export const BadgeLabel = styled(Text, {
  lineHeight: "0 !important",
});

export const ClockSvg = styled("img", {
  width: "1rem",
  height: "1rem",
});
