import { Button } from "src/components/01_atoms/button";
import { Text } from "src/components/01_atoms/text";
import { isXmasRafOperationActive } from "src/components/03_organisms/raf/_utils";
import { useRafOperation } from "src/hooks/use-raf-operation";
import { useDevice } from "src/hooks/useDevice";
import { styled, theme } from "src/styles/stitches/theme";

const Container = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 0%",
  backgroundSize: "cover",
  borderRadius: "1rem",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: theme.elevation.elevation2Primary,

  padding: "1rem 1.5rem",

  "&.xmas-active": {
    padding: "1.5rem 1.5rem",
    backgroundPosition: "50% 0%",
    gap: 38,
    border: "2px solid rgba(185, 197, 252, 1)",

    "@bp3": {
      backgroundPosition: "0% 0%",
    },
  },

  "@bp3": {
    flexDirection: "row",
  },
});

const WrapperText = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "22.25rem",
  alignItems: "center",

  "&.xmas-active": {
    maxWidth: "inherit",
    marginTop: "4rem",
    gap: "1rem",

    "@bp3": {
      marginTop: "0rem",
      gap: "1rem",
    },
  },

  "@bp3": {
    alignItems: "start",
  },
});

const Badge = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 4,
  padding: "2px 8px",
  backgroundColor: "rgba(255, 255, 255, 0.16)",
  borderRadius: 8,
});

const BadgeLabel = styled(Text, {
  lineHeight: "0 !important",
});

const ContainerTitle = styled("div", {
  margin: "1rem 0",
  span: {
    margin: 0,
    padding: 0,
    fontSize: theme.fontSizes.textDisplay3,
    lineHeight: 1.153,
    fontWeight: 700,
    letterSpacing: theme.letterSpacings.regular,
    color: "white",
  },
  ".stroked": {
    textDecoration: "line-through",
    opacity: 0.3,
  },
  variants: {
    isXmasActive: {
      true: {
        margin: "0",

        span: {
          margin: 0,
          padding: 0,
          fontSize: theme.fontSizes.textDisplay4,
          lineHeight: 1.153,
          fontWeight: 700,
          letterSpacing: theme.letterSpacings.regular,
          color: "white",
        },
      },
    },
  },
});

const Description = styled(Text, {
  textAlign: "center",
  marginBottom: "1rem",

  "@bp3": {
    marginBottom: "0px",
    textAlign: "start",
  },
});

type Props = {
  setIsRAFModalOpen?: (isOpen: boolean) => void;
  isFromModal?: boolean;
};

const MediaCardSponsorship = ({ setIsRAFModalOpen, isFromModal }: Props) => {
  const { isMobile } = useDevice();
  const { isOperation200Enabled } = useRafOperation();

  let backgroundImage = "";
  if (isXmasRafOperationActive()) {
    backgroundImage = isMobile()
      ? "url(/assets/media/mobile-card-raf-christmas-mobile.png)"
      : "url(/assets/media/mobile-card-raf-christmas-desktop.png)";
  } else if (isOperation200Enabled) {
    backgroundImage = isMobile()
      ? "url(/assets/media/mobile-card-raf-wheel.png)"
      : "url(/assets/media/desktop-card-raf-wheel.png)";
  } else {
    backgroundImage = isMobile()
      ? "url(/assets/media/background-sponsorship-media-card-mobile.png)"
      : "url(/assets/media/background-sponsorship-media-card.png)";
  }

  const renderContent = (isXmasActive: boolean) => {
    if (isXmasActive) {
      return (
        <>
          <Badge>
            <span>🎄</span>
            <BadgeLabel variant="caption1-Bold" color="textWhite">
              Offre spéciale de Noël
            </BadgeLabel>
          </Badge>
          <ContainerTitle
            isXmasActive={isXmasActive}
            style={{
              textAlign: isMobile() ? "center" : "inherit",
              paddingTop: isMobile() && !isXmasActive ? "50px" : 0,
            }}
          >
            <span>
              Gagnez entre{' '}
              <span className="stroked">20€</span>
              40€ et 200€ {isMobile() ? null : <br />} pour chaque parrainage !
            </span>
          </ContainerTitle>
        </>
      );
    }

    if (isOperation200Enabled) {
      return (
        <>
          <ContainerTitle
            style={{
              textAlign: isMobile() ? "center" : "inherit",
              paddingTop: isMobile() ? "100px" : 0,
            }}
          >
            <span>Parrainez vos proches, recevez jusqu’à 200€ !</span>
          </ContainerTitle>
        </>
      );
    }

    return (
      <>
        <ContainerTitle style={{ textAlign: isMobile() ? "center" : "inherit" }}>
          <span>Recevez </span>
          <span className="stroked">25€</span>
          <span>50€</span>
          {isFromModal ? (
            <>
              <br />
              <span>pour chaque parrainage !</span>
            </>
          ) : null}
        </ContainerTitle>
        <Description
          variant={isMobile() ? "paragraph3-Medium" : "paragraph2-Medium"}
          color="textSecondaryDefault"
        >
          {isFromModal
            ? "50€ pour vous, 1 mois offert pour vos proches"
            : "Parrainez vos proches : offrez leur leur 1er mois d’abonnement et recevez 50€."}
        </Description>
      </>
    );
  };

  return (
    <Container
      className={isXmasRafOperationActive() ? "xmas-active" : ""}
      style={{
        backgroundImage: backgroundImage,
      }}
    >
      <WrapperText className={isXmasRafOperationActive() ? "xmas-active" : ""}>
        {renderContent(isXmasRafOperationActive())}
      </WrapperText>
      {isFromModal ? null : (
        <Button
          type="button"
          variant="secondary"
          size="sm"
          aria-label="Inviter mes proches"
          style={{ alignSelf: "center" }}
          fluid={isMobile() ? true : false}
          onClick={() => setIsRAFModalOpen?.(true)}
        >
          Inviter mes proches
        </Button>
      )}
    </Container>
  );
};

export { MediaCardSponsorship };
