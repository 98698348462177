import copy from "copy-to-clipboard";
import { toast } from "src/components/01_atoms/toast";
import { objectToGetParams } from "src/utils/objectToGetParams";

// Facebook popup
const SCREEN_WIDTH = window.screen.width;
const SCREEN_HEIGHT = window.screen.height;

const getCopyInfos = (rafCode: string) => {
  const encodeRafCode = encodeURI(rafCode);

  const url = `https://parrain.dalma.co/?raf_code=${encodeRafCode}&utm_source=RAF&utm_medium=&utm_campaign=page-filleul`;
  const text = `Souscris à Dalma pour assurer ta boule de poil. Ajoute le code promo ${rafCode} au moment du paiment et reçois 1 mois gratuit ${url}`;
  const title = "Copier mon lien";

  return { url, text, title };
};

export const copyCode = (rafCode: string) => {
  const infos = getCopyInfos(rafCode);
  copy(infos.text);
  toast.copy("Le lien a été copié dans le presse papier");
};

const openFacebookPopup = (params: Parameters<typeof objectToGetParams>[0]) => {
  window.open(
    "https://www.facebook.com/sharer/sharer.php" + objectToGetParams(params),
    "Dalma",
    `width=600,height=450,left=${SCREEN_WIDTH / 2 - 300},top=${
      SCREEN_HEIGHT / 2 - 225
    },resizable,scrollbars,status`
  );
};

export const shareOnFacebook = (rafCode: string) => {
  const { url, text } = getCopyInfos(rafCode);
  openFacebookPopup({
    u: url,
    quote: text,
  });
};

const xMasOperationRaf = {
  start_date: "2024-12-01",
  end_date: "2025-01-01",
};

export const isXmasRafOperationActive = () => {
  const { start_date, end_date } = xMasOperationRaf;

  if (!start_date || !end_date) return false;

  const currentDate = new Date();
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  return currentDate >= startDate && currentDate <= endDate;
};
