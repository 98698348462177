import copy from "copy-to-clipboard";
import { Modal } from "src/components/04_layouts/modal-layout";
import { useDevice } from "src/hooks/useDevice";
import { toast } from "src/components/01_atoms/toast";
import {
  BackgroundImage,
  WrapperContent,
  WrapperTitle,
  CopyRAFCard,
  WrapperRAFCardTitle,
  PushAppCard,
  PushAppImage,
  WrappperContentPushAppCard,
  WrapperTitlePushAppCard,
  WrapperQRCode,
  QRCode,
  Badge,
  BadgeLabel,
  BadgeTime,
  WrapperHeaderContent,
  ClockSvg,
  WrapperButton,
  CloseIcon,
} from "./referral-modal-v3.styled";
import { Button } from "src/components/01_atoms/button";
import { Text } from "src/components/01_atoms/text";
import { Sponsorship200Modal } from "../sponsorship-modal-200/sponsorship-modal-200";
import { useState } from "react";
import { isXmasRafOperationActive } from "src/components/03_organisms/raf/_utils";
import { Icon } from "src/components/01_atoms/icon";
import { Link } from "src/components/01_atoms/link";

type ReferralModalV3Props = {
  isRafFModalOpen: boolean;
  setIsRAFModalOpen: (isOpen: boolean) => void;
  rafCode?: string;
};

export function ReferralModalV3({
  isRafFModalOpen,
  setIsRAFModalOpen,
  rafCode,
}: ReferralModalV3Props) {
  const { isMobile } = useDevice();
  const [isSponsorship200Open, setIsSponsorhsip200Open] = useState(false);

  let backgroundImage = "";
  if (isXmasRafOperationActive()) {
    backgroundImage = isMobile()
      ? "url(/assets/media/modale-bg-raf-christmas-mobile.png)"
      : "url(/assets/media/modale-bg-raf-christmas-desktop.png)";
  } else {
    backgroundImage = isMobile()
      ? "url(/assets/media/modal-raf-v3-mobile-background.png)"
      : "url(/assets/media/modal-raf-v3-desktop-background.png)";
  }

  return (
    <Modal
      isOpen={isRafFModalOpen}
      label="test"
      onDismiss={() => setIsRAFModalOpen(false)}
      fullScreen
    >
      <BackgroundImage
        style={{
          backgroundImage,
        }}
      >
        {isMobile() ? (
          <WrapperButton>
            <Link to="/home" color="accent" size="md" onClick={() => setIsRAFModalOpen(false)}>
              <CloseIcon>
                <Icon name="outlinedClose" color="iconBaseSubdued" size={16} />
              </CloseIcon>
            </Link>
          </WrapperButton>
        ) : null}
        <WrapperContent className={isXmasRafOperationActive() ? "xmas-active" : ""}>
          {isXmasRafOperationActive() ? (
            <WrapperHeaderContent>
              <Badge>
                <span>🎄</span>
                <BadgeLabel variant="caption1-Bold" color="textWhite">
                  Offre spéciale de Noël
                </BadgeLabel>
              </Badge>
              <BadgeTime>
                <ClockSvg src="/assets/media/clock.svg" alt="clock" />
                <BadgeLabel variant="caption1-Bold" color="textWhite">
                  Jusqu’au 31/12/24
                </BadgeLabel>
              </BadgeTime>
            </WrapperHeaderContent>
          ) : null}
          <WrapperTitle>
            <Text
              variant={{
                "@initial": "display3-Bold",
                "@bp3": "display2-Bold",
                "@bp4": "display1-Bold",
              }}
              color="textWhite"
              textAlign={isMobile() ? "center" : "start"}
              style={{ fontSize: 32 }}
            >
              {isXmasRafOperationActive() ? (
                <span>
                  Parrainez vos proches, recevez entre
                  <span className="stroked"> 20€</span>
                  40€ et 200€ !
                </span>
              ) : (
                "Parrainez vos proches, recevez jusqu’à 200€ !"
              )}
            </Text>
            <Text
              variant={{ "@initial": "paragraph2-Medium", "@bp3": "paragraph1-Medium" }}
              color="textSubtitleColor"
              textAlign={isMobile() ? "center" : "start"}
            >
              {isXmasRafOperationActive()
                ? "Pour chaque filleul qui souscrit chez Dalma entre le 1er et le 31 décembre, tournez la roue et gagnez entre 40€ et 200€ !"
                : "Offrez 1 mois gratuit et tentez de gagner entre 20€ et 200€ pour chaque proche qui rejoint Dalma."}
            </Text>
          </WrapperTitle>
          {isXmasRafOperationActive() || isMobile() ? null : (
            <CopyRAFCard>
              <WrapperRAFCardTitle>
                <Text
                  variant={{ "@initial": "paragraph2-Medium", "@bp3": "paragraph3-Medium" }}
                  color="textSubtitleColor"
                >
                  Votre code parrain
                </Text>
                <Text
                  variant={{ "@initial": "paragraph2-Medium", "@bp3": "display5-Bold" }}
                  color="textWhite"
                >
                  {rafCode}
                </Text>
              </WrapperRAFCardTitle>
              <Button
                type="button"
                variant="secondary"
                size="sm"
                aria-label="Copier code parrainage"
                style={{ width: 92 }}
                onClick={() => {
                  if (rafCode) {
                    copy(rafCode);

                    toast.copy("Le code a été copié dans le presse-papier");
                  }
                }}
              >
                Copier
              </Button>
            </CopyRAFCard>
          )}
          <PushAppCard>
            {isMobile() ? (
              <PushAppImage src="/assets/media/smartphone-qrcode-mobile.png" />
            ) : (
              <PushAppImage src="/assets/media/smartphone-qrcode.png" />
            )}
            <WrappperContentPushAppCard>
              <WrapperTitlePushAppCard>
                <Text
                  variant={{ "@initial": "paragraph2-Medium", "@bp3": "paragraph2-Bold" }}
                  color="textWhite"
                >
                  Suivez tous vos parrainages en temps réel!
                </Text>
                <Text
                  variant={{ "@initial": "paragraph2-Medium", "@bp3": "paragraph3-Medium" }}
                  color="textSubtitleColor"
                >
                  Téléchargez-vite notre app !
                </Text>
              </WrapperTitlePushAppCard>
              <WrapperQRCode>
                <QRCode />
              </WrapperQRCode>
            </WrappperContentPushAppCard>
          </PushAppCard>
          <Button
            type="button"
            variant="secondary"
            size="sm"
            aria-label="Partager le lien"
            fluid
            onClick={() => {
              if (rafCode) {
                copy(
                  `Hello !\n Rejoins-moi chez Dalma avec ta boule de poils et profite de ton 1er mois gratuit. Pour ça, clique sur mon lien : 
                  https://parrain.dalma.co/?raf_code=${encodeURI(
                    rafCode
                  )}&utm_source=RAF&utm_medium=&utm_campaign=page-filleul
                  En cas de problème, tu peux renseigner mon code ${rafCode} au moment du paiement.`
                );
                toast.copy("Votre code de parrainage a été copié dans le presse-papier");
              }
            }}
          >
            Partager mon lien de parrainage
          </Button>
          <Button
            style={{ color: "white", marginTop: 12 }}
            type="button"
            variant="smallActionAccent"
            size="sm"
            aria-label="Comment ça fonctionne"
            fluid
            onClick={() => setIsSponsorhsip200Open?.(true)}
          >
            Comment ça fonctionne ?
          </Button>
        </WrapperContent>
      </BackgroundImage>
      <Sponsorship200Modal
        rafCode={rafCode}
        isOpen={isSponsorship200Open}
        onDismiss={() => setIsSponsorhsip200Open?.(false)}
      />
    </Modal>
  );
}
